// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #282c34;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Target that specific form class (kl-private-reset-css-Xuajs1) inside .klaviyo-form-Wxd6nc */
.klaviyo-form-Wxd6nc form.kl-private-reset-css-Xuajs1 {
  float: none !important;             /* remove float: left */
  margin-left: auto !important;       /* center horizontally */
  margin-right: auto !important;      /* center horizontally */
  /* Optionally override max-width if you want it to fill the parent:
     max-width: 100% !important; */
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;aACW;AACb;;AAEA,8FAA8F;AAC9F;EACE,sBAAsB,cAAc,uBAAuB;EAC3D,4BAA4B,QAAQ,wBAAwB;EAC5D,6BAA6B,OAAO,wBAAwB;EAC5D;kCACgC;AAClC","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  text-align: center;\n  background-color: #282c34;\n  overflow: auto;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* Target that specific form class (kl-private-reset-css-Xuajs1) inside .klaviyo-form-Wxd6nc */\n.klaviyo-form-Wxd6nc form.kl-private-reset-css-Xuajs1 {\n  float: none !important;             /* remove float: left */\n  margin-left: auto !important;       /* center horizontally */\n  margin-right: auto !important;      /* center horizontally */\n  /* Optionally override max-width if you want it to fill the parent:\n     max-width: 100% !important; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
