import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // using react-router
import EmailForm from "./EmailForm";
import Chatbot from "./Chatbot";
import "./main.css"
import PrebenStart from "../components/PrebenStart";

const Main: React.FC = () => {
  const [showChatbot, setShowChatbot] = useState<boolean>(false);
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
  const [formCompleted, setFormCompleted] = useState<boolean>(false);
  
  const location = useLocation(); // Get location object from react-router

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const showFormParam = query.get("showEmailForm");
    
    const referrer = document.referrer;
    console.log('User came from:', referrer);

    const checkCookie = () => {
      return document.cookie.split(';').some((item) => item.trim().startsWith('emailFormShown='));
    };

    console.log("showFormParam        : ", showFormParam);
    console.log("emailFormShown cookie: ", checkCookie());

    if (showFormParam === '1') {
      // Explicitly show the form and set the cookie when showFormParam is '1'
      setShowEmailForm(true);
      console.log("showFormParam was explicit 1");
      } else if (showFormParam === '0') {
      // Explicitly do not show the form when showFormParam is '0'
      document.cookie = "emailFormShown=false; path=/"; // Set cookie when showing the form
      setShowEmailForm(false);
    } else if (referrer.includes('2ai.dk')) {
      // Explicitly do not show the form when referrer is on the known list
      document.cookie = "emailFormShown=false; path=/"; // Set cookie when showing the form
      setShowEmailForm(false);
    } else {
      // showFormParam is undefined or some other non-1/0 value
      // Default to NOT showing the form (same behavior as if showEmailForm=0 was set)
      setShowEmailForm(false);
      console.log("showFormParam was not defined, defaulting to NOT show form");
    }
  }, [location.search]); // Depend on the search string to re-evaluate when it changes

  
  const handleFormComplete = () => {
    setFormCompleted(true);
    setShowEmailForm(false);  // Optionally hide the form after completion
  };

  const handleStartTest = () => {
    setShowChatbot(true);
  };

  return (
    <div>
      {showChatbot ? (
        <Chatbot />
      ) : (
        <div>
          {showEmailForm ? (
            <EmailForm onFormComplete={handleFormComplete} />
          ) : (
            <div style={{ color: "white" }} className="container">
              <h1 className="h1 home">Er du klar?</h1>
              <h3 className="h3">Find din indre Preben type</h3>
              <button
                onClick={handleStartTest}
                className="btn1"
                style={{
                  backgroundColor: "#4caf50",
                  width: "140px",
                  color: "#fff",
                  marginTop: "50px",
                  padding: "12px",
                  borderRadius: "10px",
                  fontSize: "32px",
                  border: "1px solid #4caf50",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#45a049")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#4caf50")
                }
              >
                Start
              </button>
              <div className="pic home">
                <PrebenStart
                  className="image4"
                />
                {/* <img src="assets/images/PrebenStart.svg" width={600} height={400} style={{marginTop:"9rem", display:"block", marginLeft:"30%"}} alt="Description of the image"/> */}
              </div>
              <div id= "footer" className="footer" style={{ color: "white" }}  >
                <p className="h3">© 2024 Lavet med <a href="https://rollebot.dk" target="_blank" style={{ color: "green" }}>RolleBot</a> - udviklet af <a href="https://2ai.dk" target="_blank" style={{ color: "green" }}> 2ai</a>
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Main;
