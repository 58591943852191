import React, { useEffect } from 'react';

interface EmailFormProps {
  onFormComplete: () => void;
}

const EmailForm: React.FC<EmailFormProps> = ({ onFormComplete }) => {
  useEffect(() => {
    // Remove any existing Klaviyo script to avoid duplicate loading.
    const existingScript = document.querySelector('script[src*="klaviyo.js"]');
    if (existingScript) {
      existingScript.parentNode?.removeChild(existingScript);
    }

    // Create and append the Klaviyo script.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://static.klaviyo.com/onsite/js/VU2AWS/klaviyo.js';
    document.head.appendChild(script);

    // Flag to ensure we don’t react to initial (empty) state.
    let formHasLoaded = false;

    // Callback that checks if the success message is present.
    const checkForSuccess = () => {
      const formContainer = document.querySelector('.klaviyo-form-Wxd6nc');
      if (formContainer) {
        const text = (formContainer.textContent || '').trim();

        // When the form first loads Klaviyo populates it with inputs,
        // so we mark that as "loaded."
        if (!formHasLoaded && text !== '') {
          formHasLoaded = true;
          return;
        }

        // Once the form is loaded, look for our "success" indicator.
        // Klaviyo will either show the text "Tak for din tilmelding"
        // or (in some cases) clear the container.
        if (formHasLoaded && (text.includes('Tak for din tilmelding') || text === '')) {
          // Set the cookie (here valid for 30 days).
          document.cookie = "emailFormShown=true; path=/; max-age=2592000";
          // Stop further observations.
          observer.disconnect();
          // Optionally, call a callback.
          onFormComplete();
          // Reload the page.
          window.location.reload();
        }
      }
    };

    // Set up a MutationObserver on the form container.
    const observer = new MutationObserver(() => {
      checkForSuccess();
    });

    // Because Klaviyo loads the form asynchronously,
    // poll until the container is available.
    const checkForFormInterval = setInterval(() => {
      const formContainer = document.querySelector('.klaviyo-form-Wxd6nc');
      if (formContainer) {
        // Start observing changes in the container.
        observer.observe(formContainer, {
          childList: true,
          subtree: true,
          characterData: true,
        });
        clearInterval(checkForFormInterval);
      }
    }, 500);

    // Clean up on unmount.
    return () => {
      observer.disconnect();
      clearInterval(checkForFormInterval);
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [onFormComplete]);

  return (
    <div
      style={{
        /* Full height, light gray background */
        minHeight: "100vh",
        backgroundColor: "#EFF2F7",

        /* 
          Use flex to center the container horizontally in the page.
          "justifyContent: center" → center left-to-right
          "alignItems: flex-start" → keep content at the top
        */
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",

        margin: 0,
        padding: 0
      }}
    >
      {/* 
        This is our “newsletter block.” 
        - It’s limited to 540px wide 
        - It’s centered in the page by the parent flex 
      */}
      <div
        style={{
          width: "100%",
          maxWidth: "540px",
          padding: "1rem",
          boxSizing: "border-box"
        }}
      >
        {/* 1) Klaviyo form */}
        <div className="klaviyo-form-Wxd6nc" style={{ width: "100%" }} />

        {/* 2) Clear floats so the footer can’t get pushed to the right */}
        <div style={{ clear: "both" }} />

        {/* 3) Footer, left-aligned so it lines up with the form fields */}
        <div
  id="footer"
  style={{
    marginTop: "1rem",
    textAlign: "center"
  }}
>
  <p style={{ margin: 0 }}>
    © 2025 Udviklet af{" "}
    <a
      href="https://2ai.dk"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "green" }}
    >
      2ai
    </a>
    .{" "}
    <a
      href="https://2ai.dk/privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privatlivspolitik
    </a>
  </p>
</div>
      </div>
    </div>
  );
    
  };

export default EmailForm;
